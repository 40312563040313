import ApiService from './_ApiService';

class Proxy extends ApiService {
  communitySlug = '';

  useStorage = true;

  community = null;

  constructor(props) {
    super(props);
    this.baseUrl = `${window.pickit.config.PROXY_URL}/api`;
    this.proto = window.pickit.config.PROXY_PROTO;
  }

  setCommunity(slug) {
    this.community = slug;
  }

  request(path, props) {
    const args = [].slice.call(arguments);
    if (typeof props === 'undefined') {
      args.push({});
    }
    args.push(this.communitySlug || null);

    return super.request(...args);
  }

  checkLimboUser(token) {
    return this.request(`/limbo?token=${token}`).then(this.handleJSONResponse);
  }

  async activateTrial(slug) {
    await this.request(`/${slug}/limbo`, {
      method: 'PUT',
    }).then(this.handleJSONResponse);

    await this.userContext.refreshProfile();
  }

  async getLimboInformation(communitySlug, collection_slug) {
    return this.request(`/${communitySlug}/limbo/${collection_slug}`).then(
      this.handleJSONResponse
    );
  }

  async inviteLimboUserToCollection(emails, collection, communitySlug) {
    const display_name =
      this.userContext.djangoProfile?.first_name &&
      this.userContext.djangoProfile?.first_name !== 'A.'
        ? `${this.userContext.djangoProfile?.first_name} ${this.userContext.djangoProfile?.last_name}`
        : undefined;
    const collection_name = collection.title;
    emails = emails.filter(function (item, pos) {
      return emails.indexOf(item) === pos;
    });
    const emailContent = display_name
      ? {
          subject: `${display_name} has invited you to their image collection`,
          content_title: `You've been invited to ${collection_name}`,
          content_body: `${display_name} has invited you to their image collection ${collection_name} on Pickit. Click below and register for access.`,
          button_text: `Access collection`,
        }
      : {
          subject: `Someone has invited you to their image collection`,
          content_title: `You've been invited to an image collection`,
          content_body: `Someone has invited you to an image collection on Pickit. Click below and register for access.`,
          button_text: `Access collection`,
        };

    await this.request(`/${communitySlug}/limbo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        url: `https://${window.location.hostname}/#/explore/community/users/${collection?.user?.id}/collection/${collection.slug}?community_slug=${communitySlug}`,
        collection_slug: collection.slug,
        emails,
        subject: emailContent.subject,
        content_title: emailContent.content_title,
        content_body: emailContent.content_body,
        button_text: emailContent.button_text,
        first_name:
          this.userContext.djangoProfile?.first_name !== 'A.'
            ? this.userContext.djangoProfile?.first_name
            : null,
        last_name:
          this.userContext.djangoProfile?.last_name !== 'User'
            ? this.userContext.djangoProfile?.last_name
            : null,
      }),
    });
  }

  getCollectionGroups() {
    return this.request(
      `/document/${this.communitySlug}/collection-group`
    ).then(this.handleJSONResponse);
  }

  async getPickitProviderCollections() {
    return this.request(`/search/collections`).then(this.handleJSONResponse);
  }

  getSearchAutocomplete(params) {
    const search_params = {
      search: params.query,
    };
    return this.request(
      `/search/autocomplete${this.stringify(search_params)}`
    ).then(this.handleJSONResponse);
  }

  async getPickitProviderSearch(params, collectionSlug = undefined) {
    let _url = `/search`;
    if (collectionSlug !== undefined) {
      _url += `/collections/${collectionSlug}`;
    }
    return this.request(_url + this.stringify(params)).then(
      this.handleJSONResponse
    );
  }

  async updateCommunityV2(data, communitySlug) {
    return this.request(
      '/community',
      {
        method: 'PUT',
        headers: {
          'pickit-api-key': window.pickit.keys.PICKIT_API,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
      communitySlug || this.communitySlug
    ).then(this.handleJSONResponse);
  }

  async getCommunityV2(slug, with_custom_templates = false) {
    const options = {
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'Content-Type': 'application/json',
      },
    };
    const params = {
      slug: slug || this.communitySlug,
      with_custom_templates,
    };

    const request = await this.request(
      `/community${this.stringify(params)}`,
      options
    ).then(this.handleJSONResponse);
    return request.community;
  }

  async updateProfileV2(data) {
    return this.request('/profile', {
      method: 'PUT',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(this.handleJSONResponse);
  }

  getUsersV2(params, communitySlug = this.communitySlug) {
    const options = {
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': communitySlug,
      },
    };
    return this.request(
      `/community/users${this.stringify(params)}`,
      options,
      communitySlug
    ).then(this.handleJSONResponse);
  }

  leaveCommunity(slug) {
    const options = {
      method: 'POST',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ slug }),
    };
    return this.request(`/community/leave`, options).then(
      this.handleJSONResponse
    );
  }

  removeUser(user_id) {
    const options = {
      method: 'DELETE',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id }),
    };
    return this.request(`/community/users`, options).then(
      this.handleJSONResponse
    );
  }

  removeExternalUser(external_email) {
    const options = {
      method: 'DELETE',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ external_email }),
    };
    return this.request(`/community/users`, options).then(
      this.handleJSONResponse
    );
  }

  activateBusinessTrial(trial_days) {
    const options = {
      method: 'PUT',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        activate: {
          trial_days: trial_days || 30,
        },
      }),
    };
    return this.request(`/community`, options).then(this.handleJSONResponse);
  }

  getUsers(params) {
    return this.request(
      `/users/v2/?${new URLSearchParams(params).toString()}`
    ).then(this.handleJSONResponse);
  }

  getGroups(communitySlug) {
    return this.request(
      `/groups`,
      {
        withoutApi: true,
        headers: {
          'pickit-api-key': window.pickit.keys.PICKIT_API,
          'Content-Type': 'application/json',
        },
      },
      communitySlug || this.communitySlug
    ).then(this.handleJSONResponse);
  }

  async groupCreate(data) {
    return this.request('/groups', {
      method: 'POST',
      withoutApi: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: data.name,
        aad_id: data?.aad_id || null,
        aad_display_name: data?.aad_display_name || null,
        ms_token: data?.ms_token || null,
      }),
    }).then(this.handleJSONResponse);
  }

  async groupUpdate(data, slug) {
    return this.request('/groups', {
      method: 'PUT',
      withoutApi: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: data.name,
        slug,
        aad_id: data?.aad_id || null,
        aad_display_name: data?.aad_display_name || null,
        ms_token: data?.ms_token || null,
      }),
    }).then(this.handleJSONResponse);
  }

  async groupDelete(slug) {
    return this.request('/groups', {
      method: 'DELETE',
      withoutApi: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        slug,
      }),
    }).then(this.handleJSONResponse);
  }

  async groupSetUsers(slug, userIds) {
    return this.request(`/groups/${slug}`, {
      method: 'POST',
      withoutApi: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_ids: userIds,
        replace: true,
      }),
    }).then(this.handleJSONResponse);
  }

  async groupSetUser(slug, userId) {
    return this.request(`/groups/${slug}/user`, {
      method: 'POST',
      withoutApi: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: userId,
      }),
    }).then(this.handleJSONResponse);
  }

  async groupRemoveUser(slug, userId) {
    return this.request(`/groups/${slug}/user`, {
      method: 'DELETE',
      withoutApi: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: userId,
      }),
    }).then(this.handleJSONResponse);
  }

  createApiUser(body) {
    const options = {
      method: 'POST',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };
    return this.request(`/community/api_user`, options).then(
      this.handleJSONResponse
    );
  }

  createExternalUser(externalToken) {
    const options = {
      method: 'POST',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: externalToken }),
    };
    return this.request(`/community/external_user`, options).then(
      this.handleJSONResponse
    );
  }

  toggleWriteApiUser(userId, enabled) {
    const options = {
      method: 'PUT',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId, admin_access: enabled }),
    };
    return this.request(`/community/api_user/access`, options).then(
      this.handleJSONResponse
    );
  }

  generateNewSecretApiUser(userId) {
    const options = {
      method: 'PUT',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId }),
    };
    return this.request(`/community/api_user`, options).then(
      this.handleJSONResponse
    );
  }

  updateUserAlias(params) {
    const options = {
      method: 'PUT',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params.body),
    };
    return this.request(`/profile/community`, options).then(
      this.handleJSONResponse
    );
  }

  requestVerification(action, email) {
    const options = {
      method: 'POST',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      withoutApi: true,
      body: JSON.stringify({
        action,
        email,
      }),
    };
    return this.request(`/verify/new`, options).then(this.handleJSONResponse);
  }

  exploreNFSConnection(params) {
    const options = {
      method: 'GET',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      withoutApi: true,
    };
    return this.request(`/explorer/nfs${this.stringify(params)}`, options).then(
      this.handleJSONResponse
    );
  }

  exploreHubspotConnection(params) {
    const options = {
      method: 'GET',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      withoutApi: true,
    };
    return this.request(
      `/explorer/hubspot${this.stringify(params)}`,
      options
    ).then(this.handleJSONResponse);
  }

  getConnections(connections = []) {
    const options = {
      method: 'GET',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      withoutApi: true,
    };
    return this.request(
      `/connections?connections=${connections.join(',')}`,
      options
    ).then(this.handleJSONResponse);
  }

  updateConnection(configuration) {
    let connection_details = configuration.connection_details;
    if (!connection_details?.password?.length) {
      delete connection_details.password;
    }
    const options = {
      method: 'PUT',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      withoutApi: true,
      body: JSON.stringify({
        tenant_id: configuration.tenant_id,
        name: configuration.name,
        connection_details: connection_details,
      }),
    };
    return this.request(`/connections`, options).then(this.handleJSONResponse);
  }

  createConnection(configuration, connection_type = undefined) {
    const options = {
      method: 'POST',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      withoutApi: true,
      body: JSON.stringify({
        connection_type:
          configuration.connection_details.connection_type ?? connection_type,
        name: configuration.name,
        connection_details: configuration.connection_details,
      }),
    };
    return this.request(`/connections`, options).then(this.handleJSONResponse);
  }

  deleteConnection(tenant_id) {
    const options = {
      method: 'DELETE',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      withoutApi: true,
      body: JSON.stringify({
        tenant_id: tenant_id,
      }),
    };
    return this.request(`/connections`, options).then(this.handleJSONResponse);
  }

  verifyCode(code, email) {
    const options = {
      method: 'POST',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      withoutApi: true,
      body: JSON.stringify({
        verification_code: code,
        email,
      }),
    };
    return this.request(`/verify`, options).then(this.handleJSONResponse);
  }

  familyCreateChild(name) {
    const options = {
      method: 'POST',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      withoutApi: true,
      body: JSON.stringify({
        name,
      }),
    };
    return this.request(`/family/children`, options).then(
      this.handleJSONResponse
    );
  }

  familyUpdateChild(data) {
    const options = {
      method: 'PUT',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      withoutApi: true,
      body: JSON.stringify({
        data,
      }),
    };
    return this.request(`/family/children`, options).then(
      this.handleJSONResponse
    );
  }

  familyGetChildren() {
    const options = {
      method: 'GET',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      withoutApi: true,
    };
    return this.request(`/family/children`, options).then(
      this.handleJSONResponse
    );
  }

  familyRemoveChild(slugs, isRemoveFromAccount) {
    const options = {
      method: 'DELETE',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      withoutApi: true,
      body: JSON.stringify({
        slugs,
        is_remove_from_account: isRemoveFromAccount,
      }),
    };
    return this.request(`/family/children`, options).then(
      this.handleJSONResponse
    );
  }

  familyInviteChild(email) {
    const options = {
      method: 'POST',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      withoutApi: true,
      body: JSON.stringify({
        email,
      }),
    };
    return this.request(`/family/invite`, options).then(
      this.handleJSONResponse
    );
  }

  familyInviteDecision(decision) {
    const options = {
      method: 'PUT',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      withoutApi: true,
      body: JSON.stringify({
        decision,
      }),
    };
    return this.request(`/family/invite`, options).then(
      this.handleJSONResponse
    );
  }

  familyGetSSO() {
    const options = {
      method: 'GET',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      withoutApi: true,
    };
    return this.request(`/family/sso`, options).then(this.handleJSONResponse);
  }

  familyUpdateSSO(data) {
    const options = {
      method: 'PUT',
      headers: {
        'pickit-api-key': window.pickit.keys.PICKIT_API,
        'pickit-community-slug': this.communitySlug,
        'Content-Type': 'application/json',
      },
      withoutApi: true,
      body: JSON.stringify({
        data,
      }),
    };
    return this.request(`/family/sso`, options).then(this.handleJSONResponse);
  }

  async generateOTP() {
    const request = await this.request(`/web-auth/connect`).then(
      this.handleJSONResponse
    );
    return request;
  }
}

export default Proxy;
