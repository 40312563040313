/* global Office */
/* eslint-disable */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './config';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { analytics } from 'services';
import { inTeams, isIE11 } from 'utils/platform';
import { loadScript } from 'utils';
import localStorageWrapper from 'utils/localStorageWrapper';

const Root = React.lazy(() =>
  import(/* webpackChunkName: "component.Root" */ 'Root')
);

const polyfills = [];
if (isIE11) {
  document.body.classList.add('Platform--IE11');
  polyfills.push(
    import(/* webpackChunkName: "polyfill-ie11" */ './utils/polyfills/ie11')
  );
} else {
  Promise.all([
    import('./serviceWorkerRegistration'),
    import('@3m1/service-worker-updater'),
  ]).then(([serviceWorkerRegistration, { onServiceWorkerUpdate }]) => {
    serviceWorkerRegistration.register({
      onUpdate: onServiceWorkerUpdate,
    });
  });
}
if (!window.fetch) {
  polyfills.push(
    import(/* webpackChunkName: "polyfill-fetch" */ './utils/polyfills/fetch')
  );
}

polyfills.push(import('core-js/modules/es.symbol.async-iterator'));

if (window.location?.href?.includes('?embed')) {
  window.addEventListener('hashchange', () => {
    window?.parent?.postMessage(
      JSON.stringify({
        key: 'REDIRECT',
        value: window.location.hash,
      }),
      '*'
    );
  });
}

if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function (callback, type, quality) {
      var canvas = this;
      setTimeout(function () {
        var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
          len = binStr.length,
          arr = new Uint8Array(len);

        for (var i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }

        callback(new Blob([arr], { type: type || 'image/png' }));
      });
    },
  });
}

Promise.all(polyfills)
  .then(runApp)
  .catch(function (e) {
    console.error(e);
  });

async function runIfOffice() {
  if (window.Office) {
    setTimeout(() => {
      render();
    }, 2500);
    Office.initialize = function () {};
    const info = await Office.onReady();
    console.info(`Office.js is now ready in ${info.host} on ${info.platform}`);
    window.pickit.config.host = info.host;
    window.pickit.config.platform = info.platform;
    render();
  } else {
    setTimeout(runIfOffice, 200);
  }
}

async function runApp() {
  if (inTeams()) {
    await loadScript(
      'https://statics.teams.microsoft.com/sdk/v1.5.2/js/MicrosoftTeams.min.js'
    );
    window.microsoftTeams?.initialize?.();
  }
  if (
    window.location.href.includes('_host_Info') ||
    !!Array.from(window.location?.ancestorOrigins || []).find((str) =>
      str.includes('officeapps')
    ) || localStorageWrapper.getItem("office") === "true"
  ) {
    localStorageWrapper.setItem("office", "true");
    loadScript('https://appsforoffice.microsoft.com/lib/1/hosted/Office.js');
    runIfOffice();
  } else {
    console.log('Running app render');
    render();
  }
}

function render() {
  const rootContainer = document.getElementById('root');
  ReactDOM.render(
      <React.Suspense fallback={<></>}>
        <Root />
      </React.Suspense>,
    rootContainer
  );
  analytics.trackEvent('Application Started');
}
