import { Storage } from 'services';
import ApiService from './_ApiService';

class Manage extends ApiService {
  communitySlug = '';

  useStorage = true;

  constructor(props) {
    super(props);
    if (this.useStorage && window.pickit && window.pickit.community) {
      this.setSlug(window.pickit.community);
    }

    if (this.useStorage && Storage.getItem('communitySlug')) {
      this.setSlug(Storage.getItem('communitySlug'));
    }
  }

  request(path, props) {
    const args = [].slice.call(arguments);
    if (typeof props === 'undefined') {
      args.push({});
    }
    args.push(this.communitySlug || null);

    return super.request(...args);
  }

  acceptOwnership() {
    return this.request(`/settings/community/owner/accept/`, {
      method: 'POST',
    }).then(this.handleJSONResponse);
  }

  declineOwnership() {
    return this.request(`/settings/community/owner/decline/`, {
      method: 'POST',
    }).then(this.handleJSONResponse);
  }

  async groupGet(slug) {
    return this.request(`/groups/${slug}/?fields=users`).then(
      this.handleJSONResponse
    );
  }

  async groupDelete(slug) {
    return this.request('/groups/', {
      method: 'DELETE',
      body: JSON.stringify({
        slug,
      }),
    }).then(this.handleJSONResponse);
  }

  async getGroups(communitySlug = this.communitySlug) {
    return this.request('/groups/', {}, communitySlug).then(
      this.handleJSONResponse
    );
  }

  async inviteUsers(users) {
    return this.request('/users/v2/invite/', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(users),
    });
  }

  async makeOwner(params) {
    const options = {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return this.request('/settings/community/owner/assign/', options).then(
      this.handleJSONResponse
    );
  }

  getContributionDownloadLinks(slug) {
    return this.request(
      `/contributions/${slug}/download_links/?with_token=1&show_initial=1`
    ).then(this.handleJSONResponse);
  }

  getContribution(slug) {
    return this.request(`/contributions/${slug}/`).then(
      this.handleJSONResponse
    );
  }

  getCollections(page_nr) {
    return this.request(`/search/collections?page_nr=${page_nr}`).then(
      this.handleJSONResponse
    );
  }

  getCollection(userId, slug, params) {
    return this.request(
      `/users/${userId}/collection/${slug}/${this.stringify(params)}`
    ).then(this.handleJSONResponse);
  }

  getMission(slug) {
    return this.request(`/missions/${slug}/`).then(this.handleJSONResponse);
  }

  buyContribution(slug) {
    return this.request(`/award_contribution/`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: {
        contribution_slug: slug,
      },
    });
  }

  addToCollection(userId, collectionSlug, contributionSlug) {
    return this.request(
      `/users/${userId}/collection/${collectionSlug}/add/photo`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        withoutApi: true,
        method: 'POST',
        body: {
          contribution_slug: contributionSlug,
        },
      }
    );
  }

  removeFromCollection(userId, collectionSlug, contributionSlug) {
    return this.request(
      `/users/${userId}/collection/${collectionSlug}/contribution/${contributionSlug}/delete/`,
      {
        method: 'POST',
      }
    );
  }

  removeContributionPermanent(userId, contributionSlug) {
    return this.request(`/users/${userId}/remove/photo/permanent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        contributions: [
          {
            contribution_slug: contributionSlug,
          },
        ],
      }),
    });
  }

  contributionCollections(userId, contributionSlug, page_nr) {
    return this.request(
      `/users/${userId}/contribution/${contributionSlug}/check/collections/?page_nr=${
        (page_nr || 0) + 1
      }&page_limit=200`
    ).then(this.handleJSONResponse);
  }

  updateContribution(contributionSlug, data) {
    return this.request(`/contributions/${contributionSlug}/update`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  updateCommunity(communityId, data) {
    return this.request(`/community/${communityId}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  getCollectionGroup(slug) {
    return this.request(`/collection_groups/${slug}/`).then(
      this.handleJSONResponse
    );
  }

  createCollectionGroup(data) {
    return this.request(`/collection_groups/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(this.handleJSONResponse);
  }

  deleteCollectionGroup(collectionGroupSlug) {
    return this.request(`/collection_groups/${collectionGroupSlug}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(this.handleJSONResponse);
  }

  addCollectionsToCollectionGroup(collectionGroupSlug, collectionSlugs) {
    return this.request(`/collection_groups/${collectionGroupSlug}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        collectionSlugs.map((collectionSlug) => ({
          collection_slug: collectionSlug,
        }))
      ),
    }).then(this.handleJSONResponse);
  }

  removeCollectionsFromCollectionGroup(collectionGroupSlug, collectionSlugs) {
    return this.request(`/collection_groups/${collectionGroupSlug}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        collectionSlugs.map((collectionSlug) => ({
          collection_slug: collectionSlug,
        }))
      ),
    }).then(this.handleJSONResponse);
  }

  modifyCollectionGroup(collectionGroupSlug, data) {
    return this.request(`/collection_groups/${collectionGroupSlug}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(this.handleJSONResponse);
  }

  /*
  {
    "user_id": 123, <-- specific user
    "permissions": "view", <--- just skip it, we have no other permission for now
    "group_name": "user" <--- this is default, you might send any group slug from Groups
  }
  */
  setCollectionGroupPermission(collectionGroupSlug, permission, userId) {
    let method = 'POST';
    let body = null;
    if (permission === 'none') {
      method = 'DELETE';
    }
    if (permission === 'all') {
      method = 'PUT';
    }
    if (permission === 'specific') {
      body = JSON.stringify({
        user_id: userId,
        permissions: 'view',
        group_name: collectionGroupSlug,
      });
    }
    return this.request(
      `/collection_group/${collectionGroupSlug}/permission/`,
      {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }
    ).then(this.handleJSONResponse);
  }
}

export default Manage;
